<template>
  <div class="lecture">
    <CommonTopSearch ref="CommonTopSearchRef"
                     background="#303FD4"
                     @initData="initData" />

    <!-- 下拉选择框 -->
    <div class="select_subject">
      <dropDown :list='typeList'
                :index='idx'
                attribute_name=''
                @changeMenu='checkOneChapter' />
    </div>
    <div class='videowrap'>
      <div class="videos"
           id="videos"
           ref="videosRef">
        <div :class="`video ${getclass(item)}`"
             v-for="(item,index) in videoList"
             :key="index">
          <!-- :style="{'margin-right':(index+1) % 5 == 0 ? '0' : '30rem','margin-top':index<4?'0':'30px'}" -->
          <div class="subject">
            {{item.subject_name}}
          </div>
          <div class="desc">
            {{item.filename || item.knowledge_desc}}
          </div>
          <div class="star"
               v-if="idx == 0">
            <img src="@/static/start.png"
                 v-for="itm in item.knowledge_point_star"
                 :key="itm.knowledge_id"
                 alt="">
          </div>

          <div class="btn"
               :style="{'background':backgColor[item.subject_id].videoColor}"
               @click="checkVideo(item)">
            {{idx?'开始播放':'视频列表'}}<img src="@/static/goodVideo/icon-播放@2x.png" />
          </div>
        </div>
        <!-- 占位 -->
        <div class="video1"></div>
        <div class="video1"></div>
        <div class="video1"></div>
        <div class="video1"></div>
        <!-- 占位 -->
      </div>
    </div>
    <OtherForm ref="otherFormRef" />

  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import OtherForm from '././components/otherForm.vue'
import { backgoundColor } from '../course/components/background.js'
import { instance } from '@/utils/http.js'
import CommonTopSearch from '../components/COMMON_TOP_SEARCH/index.vue'
import dropDown from '@/views/components/dropdown/index.vue'

let backgColor = backgoundColor
let router = useRouter()
let idx = ref(0)
let typeList = [
  '知识点讲解',
  '专题讲解',
]
let videoList = ref([])
let urlList = [
  '/api/v1/video/knowledge_lst',
  '/api/v1/video/lst'
]
let page = 0
let limit = 8
let count = 0
let subject_id = 0
let active = false
let id = ref('')
let keywords = ref('')
let otherFormRef = ref()

const refresh = () => {
  initData(id.value, keywords.value)
}
const checkOneChapter = (index) => {
  idx.value = index
  refresh()
}
const getclass = (item) => {
  return `s${item.subject_id}`
}
const checkVideo = (row) => {
  if (idx.value) {
    router.push('/course/preview?type=video&url=' + row.url + "&filename=" + row.filename + "&subject_name=" + row.subject_name)
  } else {
    otherFormRef.value.dialogVisible = true
    otherFormRef.value.subjectId = row.knowledge_id
    otherFormRef.value.getKnowledgeList(row.knowledge_id)
  }
}
const initData = async (id1, keywords1) => {
  id.value = id1
  keywords.value = keywords1
  const { data } = await instance({
    url: urlList[idx.value],
    method: 'get',
    params: {
      subject_id: id.value,
      keywords: keywords.value,
      page: page,
      limit: limit
    }
  })
  videoList.value = data.list

}
</script>

<style lang="scss" scoped>
.lecture {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-image: url("../../static/allBackg/bg-公益视频.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.videowrap {
  height: calc(100vh - 240rem);
  overflow: scroll;
  margin-top: 40rem;
}

.videos {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
  width: 100%;
  padding: 10rem 50rem;

  justify-content: space-between;
  .video1 {
    /**布局占位 */
    width: 340rem;
  }
  .video {
    position: relative;
    width: 340rem;
    height: 250rem;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 28rem;
    overflow: hidden;
    .subject {
      font-size: 24rem;
      font-weight: bold;
      color: #ffffff;
      display: flex;
      justify-content: center;
      height: 44rem;
      align-items: center;
    }
    .desc {
      width: calc(100% - 20rem);
      margin: 0 auto;
      margin-top: 10rem;
      text-align: center;
      font-weight: bold;
      font-size: 24rem;
      color: #000000;
      // height: 86rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .star {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
      img {
        font-size: 20rem;
        height: 20rem;
      }
    }
    .btn {
      width: 150rem;
      height: 44rem;
      font-size: 22rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 30rem;
      border-radius: 35rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
      img {
        width: 24rem;
        margin-left: 6rem;
      }
    }
  }
}

.s1 {
  background-image: url("../../static/goodVideo/bg-语文@2x.png");
}
.s7 {
  background-image: url("../../static/goodVideo/bg-道德与法治@2x.png");
}
.s10 {
  background-image: url("../../static/goodVideo/bg-化学@2x.png");
}
.s5 {
  background-image: url("../../static/goodVideo/bg-化学@2x.png");
}
.s6 {
  background-image: url("../../static/goodVideo/bg-历史@2x.png");
}
.s9 {
  background-image: url("../../static/goodVideo/bg-生物@2x.png");
}
.s2 {
  background-image: url("../../static/goodVideo/bg-数学@2x.png");
}
.s4 {
  background-image: url("../../static/goodVideo/bg-物理@2x.png");
}
.s3 {
  background-image: url("../../static/goodVideo/bg-英语@2x.png");
}

.loading_warp {
  display: flex;
  justify-content: center;

  ::v-deep .el-button {
    border: none;
    background: none;
  }
}
::v-deep .is-loading:before {
  opacity: 0;
}

.select_subject {
  position: absolute;
  top: 30rem;
  right: 40rem;
  background: #303fd4;
  border-radius: 35rem;

  /* .mock_btn {
    width: 240rem;
    height: 60rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 32rem;
    font-weight: 500;

    img {
      width: 26rem;
      height: 26rem;
      margin-left: 12rem;
    }
  }*/
}
</style>