<template>
  <div>
    <div class="back"
         v-if="showBack">
      <img src="@/static/icon-back.png"
           @click="back"
           alt="">
    </div>
    <div class="subjectabs"
         :style="{'background':background}">
      <div class="subject_list">
        <div :class="{'item':true,'active':subject_id == item.subject_id}"
             v-for="(item,index) in subjectList"
             @click="checkSubject(item.subject_id)"
             :key="index">
          {{item.subject_name}}
        </div>
      </div>
      <!-- v-if="showSearch" -->
      <div class="search">
        <img src="@/static/baisearch.png"
             @click="showDialog"
             v-if="showSearch"
             alt="">
      </div>
    </div>
    <formDialog ref="formDialogRef"
                @getData="getData"
                :showTag="showTag" />
  </div>
</template>

<script setup>
import formDialog from './search.vue'
import { getUserSubject } from '@/api/subject.js'
import { instance } from '@/utils/http.js'
import { onMounted, ref } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
const { showSearch, showBack, url, background, isDefault, isDefaultDialog } = defineProps({
  showSearch: {
    type: Boolean,
    default: true
  },
  showBack: {
    type: Boolean,
    default: true
  },
  // 暂时先留着  可能不同模块获取科目的接口不同
  url: {
    type: String,
    default: '/api/v1/public/subjects'
  },
  background: {
    type: String,
    default: '#3e8ef3'
  },
  // 使用默认地址
  isDefault: {
    type: Boolean,
    default: true
  },
  showTag: {
    type: Boolean,
    default: false
  },
  isDefaultDialog: {
    type: Boolean,
    default: true
  }
})
const emit = defineEmits(['initData', 'showSearch'])
const router = useRouter()
const store = useStore()

let keywords = ref('')
let subject_id = ref('')
let tagId = ref([])
let subjectList = ref([])
// 查询弹窗ref
let formDialogRef = ref(null)
subjectList.value = store.state.subject.subjectList

onMounted(() => {
  if (isDefault) {
    getSubject()
  } else {
    subjectList.value = []
    getSubject1()
  }

})

const getData = (keyword, tag_id) => {
  keywords.value = keyword
  tagId.value = tag_id
  refresh()
}

const refresh = () => {
  let subject_name = subjectList.value.find(item => item.subject_id == subject_id.value).subject_name
  emit('initData', subject_id.value, keywords.value, subject_name, tagId.value)
}

// 获取科目 触发父组件方法
const getSubject = async () => {
  if (!subjectList.value.length) {
    const { data } = await getUserSubject()
    subjectList.value = data.list
    store.commit('subject/setSubjectList', data.list)
  }
  subject_id.value = subjectList.value[0].subject_id
  refresh()
}
// 获取科目 触发父组件方法
const getSubject1 = async () => {
  const { data } = await instance({
    url: url,
    method: 'get',
  })
  subjectList.value = data.list
  subject_id.value = subjectList.value[0].subject_id
  refresh()
}

// 选择科目
const checkSubject = (id) => {
  subject_id.value = id
  keywords.value = ''
  refresh()
}
// 点击返回
const back = () => {
  router.go(-1)
}
// 点击查询
const showDialog = () => {
  if (isDefaultDialog) {
    formDialogRef.value.dialogVisible = true
  } else {
    emit('showSearch')
  }
  // dialogVisible.value = true
}

defineExpose({
  refresh, subject_id
})

</script>

<style lang="scss" scoped>
.back {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 60rem;
    height: 60rem;
    margin-top: 30rem;
    margin-left: 40rem;
    box-shadow: 0px 0px 20px #3b9edd;
    border-radius: 50%;
    cursor: pointer;
  }
  div {
    cursor: pointer;
  }
}
.subjectabs {
  background-color: #3e8ef3;
  width: 1680rem;
  max-width: 100%;
  height: 80rem;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
  // display: flex;
  // justify-content: space-around;
  border-radius: 67rem;
  margin: 0 auto;
  position: relative;
  margin-top: 38rem;
  padding: 0 76rem 0 56rem;
  .subject_list {
    max-width: 100%;
    height: 100%;
    display: flex;
    // justify-content: space-around;
    overflow: auto;
    overflow-y: hidden;
  }
  .item {
    // margin: 0 40rem;
    margin: 0 auto;
    padding: 0 10rem;
    font-size: 32rem;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
  }
  .search {
    position: absolute;
    right: 30rem;
    top: 19rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 36rem;
      height: 36rem;
    }
  }
}
.active {
  color: #ffffff !important;
  font-weight: 500;
}
</style>